<template>
<div class="content">
    <div class="row">

        <div class="col-md-12">
            <vuestic-widget :headerText="$t('view.productbystation.update')">
                <div class="row">
                    <div class="col-md-3">
                        <button type="button" v-on:click="backPage" class="btn btn-primary btn-sm">Back</button>
                    </div>
                    <div class="col-md-3 offset-md-6">
                        <!-- <button type="button" class="btn btn-warning btn-sm">Delete Element</button> -->
                    </div>

                </div>

            </vuestic-widget>
        </div>
        <div class="col-md-12">
            <vuestic-widget>

                <div class="row">

                    <div class="col-md-6">
                        <vuestic-simple-select :label="'view.productbystation.fields.product' | translate" v-model="product" option-key="name" v-bind:options="products">
                        </vuestic-simple-select>

                    </div>
                    <div class="col-md-6">
                        <div class="form-group with-icon-right" :class="{'has-error': errors.has('price'), 'valid': isSuccessfulNumberValid}">
                            <div class="input-group">
                                <input id="price" name="price" v-model="price" v-validate="'required'" />
                                <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                <i class="fa fa-check valid-icon icon-right input-icon"></i>
                                <label class="control-label" for="price">{{'view.productbystation.fields.price' | translate}} </label><i class="bar"></i>
                                <small v-show="errors.has('price')" class="help text-danger">
                                                        {{ errors.first('price') }}
                                                    </small>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="row">
                    <div class="col-md-8">
                    </div>
                    <div class="col-md-4">
                        <label class="control-label" for="location">{{'view.station.fields.status' | translate}} </label>

                        <div class="form-group with-icon-right" :class="{'has-error': errors.has('active')}">

                            <div class="input-group">
                                <vuestic-switch v-model="active">
                                    <span slot="trueTitle">{{'view.event.fields.enabled' | translate}}</span>
                                    <span slot="falseTitle">{{'view.event.fields.disabled' | translate}}</span>
                                </vuestic-switch>
                                <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                <i class="fa fa-check valid-icon icon-right input-icon"></i>

                                <small v-show="errors.has('active')" class="help text-danger">
                                                        {{ errors.first('active') }}
                                                    </small>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <button type="button" v-on:click="updateProductOnStation" class="btn btn-primary btn-sm">{{$t('view.productbystation.save')}}</button>
                </div>

            </vuestic-widget>

        </div>
    </div>
</div>
</template>

<script>
import Vue from 'vue'

import {
    SpringSpinner
} from 'epic-spinners'
import {
    mapState,
    mapActions
} from 'vuex'
export default {
    name: 'new-station',
    components: {
        SpringSpinner,
    },
    mounted() {
        // checkbox and radios
        this.loadProducts()
            .then(data => {

            })
            .catch(data => {
                this.addToastMessage({
                    text: 'Error consultando usuarios',
                    type: 'warning'
                })
            })
        var id = this.$route.params.idproduct;
        if (id !== null) {
         //   console.log('LOADING PRODUCT STATION:'+id);
            this.loadProductStation(id)
                .then(data => {
                    this.product.id = data.id_product;
                    this.price = data.price;
                   
                })
                .catch(data => {
                    this.addToastMessage({
                        text: "Ocurrio un error cargando el Producto",
                        type: "success"
                    });
                });

                  //  console.log('PRICE:'+this.price);
        } else {
            this.addToastMessage({
                text: "Ocurrio un error en el ID",
                type: "success"
            });
        }

    },
    computed: {
        ...mapState({
            products: state => state.product.products,
            idevent: state => state.event.event.id
        }),
        isSuccessfulNameValid() {
            let isValid = false
            if (this.formFields.name) {
                isValid = this.formFields.name.validated && this.formFields.name.valid
            }
            return isValid
        },
        isSuccessfulNumberValid() {
            let isValid = false
            if (this.formFields.price) {
                isValid = this.formFields.price.validated && this.formFields.price.valid
            }
            return isValid
        },
        isSuccessfulLocationValid() {
            let isValid = false
            if (this.formFields.location) {
                isValid = this.formFields.location.validated && this.formFields.location.valid
            }
            return isValid
        },
    },
    data() {
        return {
            header: 'Nueva Estación',
            productoptions: null,
            price: '',
            location: '',
            product: '',
            idproduct: '',
            active: 1,
            eventoptions: null,

        }
    },
    methods: {
        ...mapActions([
            'loadProducts',
            'updateProductStation',
            'addToastMessage',
            'loadProductStation'
        ]),
        backPage: function () {
            this.$router.go(-1)
        },
        updateProductOnStation() {
            this.$validator.validate().then(result => {
                if (result) {
                    var idstation = this.$route.params.idstation;
                    this.updateProductStation({
                            id_product: this.idproduct.id,
                            id_station: idstation,
                            price: this.price,
                            active: this.active,
                        })
                        .then((data) => {
                            this.addToastMessage({
                                text: data.message,
                                type: 'success'
                            })
                            setTimeout(() => this.$router.go(-1), 2000)
                        })
                        .catch((data) => {
                            this.error = data.message
                            this.addToastMessage({
                                text: data.message,
                                type: 'warning'
                            })
                        })
                }
            })
        },
        trashStation() {

            var id = this.$route.params.id;
           // console.log(id);
            this.deleteStation({
                    id: id
                })
                .then((data) => {
                    this.addToastMessage({
                        text: data.message,
                        type: "warning"
                    });
                    setTimeout(() => this.$router.go(-1), 100);
                })
                .catch(data => {
                    this.error = data.message;
                    this.errors = data.errors || {};
                });
            //this.$refs.vuetable.refresh();
        },
        cleanform() {
            this.name = null;
            this.description = null;
        }
    }
}
</script>

<style lang="scss">
.color-icon-label-table {
    td:first-child {
        width: 1rem;
    }
}
</style>
